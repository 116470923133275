@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.title-text {
    position: absolute;
    font-size: 2.5rem;
    color: #fff;
    top: 40%;
    left: 20%;
    overflow-wrap: normal;
}

#title-button {
    position: absolute;
    top: 65%;  
    left: 50%; 
    transform: translate(-50%, -50%);

    font-size: 1em;
    padding: 1em 2em;
    margin-top: 100px;
    margin-bottom: 60px;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ff0081;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
}

#container {
	z-index: 100;
	display: block;
    color: white;
}

#main {
    position: absolute;
    width: 100%;
    
}

.project-select {
    
}

.large-select {
    font-size: 5em;
    text-align: right;
    color: #d5d5d5;
    -webkit-text-fill-color: rgba(225,255,255,0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #d5d5d5;
    -webkit-text-stroke: 2px #d5d5d5;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out; /*gecko*/
    -ms-transition: all 1s ease-out; /*IE10*/
    -o-transition: all 1s ease-out; /*opera 11.10+*/
    -pie-transition: all 1s ease-out; /*PIE*/
    transition: all 1s ease-out;
}

.large-select:hover {
    color: #000;
    -webkit-text-fill-color: rgba(255,255,255,1);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000;
    -moz-transition: all 1s ease-out; /*gecko*/
    -ms-transition: all 1s ease-out; /*IE10*/
    -o-transition: all 1s ease-out; /*opera 11.10+*/
    -pie-transition: all 1s ease-out; /*PIE*/
    transition: all 1s ease-out;
}